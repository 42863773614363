// @flow
import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Post from "../components/Post";

export default ({ data }: { data: Object }) => (
  <Layout>
    {data.allMarkdownRemark.edges.map(({ node }) => {
      const { id, frontmatter, excerpt, fields } = node;
      return (
        <Post
          id={id}
          title={frontmatter.title}
          date={frontmatter.date}
          body={excerpt}
          slug={fields.slug}
        />
      );
    })}
  </Layout>
);

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM Do, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;
